.offcanvas {
    color: var(--drawer-text-color);
    background-color: var(--drawer-bg-color);
}

.offcanvas-end, .offcanvas-start, .offcanvas-top {
    top: 56px;
}

.offcanvas-bottom {
    height: 40vh !important;
}