.accordion-header {
    font-weight: bold;
    color: var(--accordion-header-text-color); 
}

.accordion-item { 
    background-color: var(--accordion-body-bg-color);
    color: var(--accordion-body-text-color);
    border-color: var(--accordion-body-border-color);

    div[role="tabpanel"] {
      background-color: var(--accordion-header-bg-color); 

      .accordion-body {
        background-color: var(--accordion-active-body-bg-color); 
      }
    }
}

.accordion-button {
    &:not(.collapsed) {
        color: var(--accordion-header-text-color); 
        background-color: var(--accordion-body-bg-color);
        box-shadow: var(--accordion-body-box-shadow);
    }

    &.collapsed {
        color: var(--accordion-header-collapsed-text-color); 
        background-color: var(--accordion-header-collapsed-bg-color);
    }

    &:focus {
        border-color: var(--accordion-button-focus-border-color);
        box-shadow: var(--accordion-button-focus-box-shadow);
    }
}
