input[type='radio'] {
    accent-color: var(--radio-accent-color);

    &:hover {
        accent-color: var(--radio-hover-accent-color);
    }

    &:focus {
        box-shadow: 0 0 0 0.25rem var(--radio-focus-boxshadow-color);
    }
}
