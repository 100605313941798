.modal-content {
    background-color: var(--bs-body-bg);
    color: var(--body-text-color);
}

.modal-title {
    word-break: break-all;
}

.scrollable-modal {
    max-height: calc(var(--vh) * 100 - 198px);
    overflow: auto;
}
